// extracted by mini-css-extract-plugin
export var bottom = "gallery-screen-module--bottom--2c23e";
export var container = "gallery-screen-module--container--af8c4";
export var grid = "gallery-screen-module--grid--6d419";
export var image = "gallery-screen-module--image--038b7";
export var name = "gallery-screen-module--name--e3ded";
export var next = "gallery-screen-module--next--07672";
export var position = "gallery-screen-module--position--473c4";
export var prev = "gallery-screen-module--prev--b3907";
export var quote = "gallery-screen-module--quote--cba86";
export var section = "gallery-screen-module--section--dc331";
export var slide = "gallery-screen-module--slide--f4b02";
export var slider = "gallery-screen-module--slider--1abc7";
export var sliderNav = "gallery-screen-module--slider-nav--2b12a";
export var sliderNavContainer = "gallery-screen-module--slider-nav-container--a3555";
export var text = "gallery-screen-module--text--591cf";
export var top = "gallery-screen-module--top--d7e47";