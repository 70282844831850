// extracted by mini-css-extract-plugin
export var card = "history-screen-module--card--0fddd";
export var cardHeading = "history-screen-module--card-heading--74a0c";
export var cardText = "history-screen-module--card-text--00635";
export var cardTitle = "history-screen-module--card-title--dd1ce";
export var grid = "history-screen-module--grid--e5a39";
export var label = "history-screen-module--label--9d0a8";
export var left = "history-screen-module--left--98db4";
export var leftC1 = "history-screen-module--left-c-1--99f71";
export var leftC2 = "history-screen-module--left-c-2--9a0de";
export var leftC3 = "history-screen-module--left-c-3--5af12";
export var leftC4 = "history-screen-module--left-c-4--7336d";
export var leftC5 = "history-screen-module--left-c-5--c7067";
export var right = "history-screen-module--right--e2931";
export var rightC1 = "history-screen-module--right-c-1--92bb9";
export var rightC2 = "history-screen-module--right-c-2--39dd9";
export var rightC3 = "history-screen-module--right-c-3--5e737";
export var rightC4 = "history-screen-module--right-c-4--545b4";
export var rightC5 = "history-screen-module--right-c-5--3f54c";
export var section = "history-screen-module--section--348c3";
export var title = "history-screen-module--title--bf9c6";
export var year = "history-screen-module--year--6de01";